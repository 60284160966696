import React from "react";

export default function APISources() {
  return {
    Authentication: {
      LoginURI: "/auth/checkin",
      LogoutURI: "/auth/logout",
      OtpURI: "/auth/checkotplogin",
      ResendURI: "/auth/resendcode",
      ForgotURI: "/auth/checkmail",
      ForgotCodeURI: "/auth/checkcode",
      ResetPasswordURI: "/auth/resetpwd",
    },
    Header: {
      ListNotificationsURI: "/notifications",
      ListAllNotificationsURI: "/notifications/viewall",
      UpadteNotificationURI: "/notifications/notification",
      ClearAllNotificationsURI: "/notifications/clearall",
      ListConversationsURI: "/conversations",
      UpadteConversationURI: "/conversations/conversation",
      ClearAllConversationsURI: "/conversations/clearall",
      ListAllConversationsURI: "/conversations/viewall",
    },
    Roles: {
      ListURI: "/roles",
      CreateURI: "/roles/create",
      DetailsURI: "/roles/role",
      UpdateURI: "/roles/update",
      DeleteURI: "/roles/delete",
    },

    Addons: {
      ListURI: "/addons",
      CreateURI: "/addons/create",
      DetailsURI: "/addons/addon",
      UpdateURI: "/addons/update",
      DeleteURI: "/addons/delete",
    },
    Agent: {
      CreateURI: "/agents/create",
      DetailsURI: "/agents/agent",
      UpdateURI: "/agents/update",
      DeleteURI: "/agents/delete",
      UploadFileURI: "/agents/attachment",
      RemoveFileDataURI: "/agents/removeAttachment",
      PartnersURI: "/agents/partners",
      AgentStatusURI: "/agents/status",
      PartnerSubscriptionsURI: "/agents/partnerservicedetail",
      SoftwaresByServiceURI: "/agents/getsoftwaresbyservice",
    },
    Partners: {
      ListURI: "/partners",
      CreateURI: "/partners/create",
      DetailsURI: "/partners/partner",
      UpdateURI: "/partners/update",
      DeleteURI: "/partners/delete",
      UploadFileURI: "/partners/attachment",
      PartnerServicesURI: "/services/fetchCategoryData",
      AddonsURI: "/partners/addons",
      ServiceDetailURI: "/partners/getservicedetails",
      CreateFileDataURI: "/partners/createAttachment",
      RemoveFileDataURI: "/partners/removeAttachment",
      SoftwaresByServiceURI: "/partners/getsoftwaresbyservice",
      PriceBySortwareURI: "/partners/getpricebysoftware",
      ServicesDropdownURI: "/partners/getotherservices"
    },
    Services: {
      ListURI: "/services",
      CreateURI: "/services/create",
      DetailsURI: "/services/service",
      UpdateURI: "/services/update",
      DeleteURI: "/services/delete",
      SoftwareDetailURI: "/services/getsoftware",
      AddedServicesURI: "/addons",
      ServiceStatusURI: "/services/status",
    },
    Softwares: {
      ListURI: "/softwares",
      CreateURI: "/softwares/create",
      DetailsURI: "/softwares/software",
      UpdateURI: "/softwares/update",
      DeleteURI: "/softwares/delete",
      AllListURI: "/softwares/getsoftwarelists"
    },
    Users: {
      ListURI: "/users",
      CreateURI: "/users/create",
      DetailsURI: "/users/user",
      UpdateURI: "/users/update",
      DeleteURI: "/users/delete",
      UserStatusURI: "/users/status",
      UserCustomersURI: "/users/customers",
      UserBranchesURI: "/users/branches",
      UserRolesURI: "/users/roles",
      UserPartnersURI: "/users/partners",
      UserAgentsURI: "/users/agents",
      ListFilterRoleURI: "/users/filterRoles",
      listFilterCustomersURI: "/users/filtercustomers",
      ListFilterBranchURI: "/users/filterbranches",
    },
    Branches: {
      ListURI: '/branches',
      CreateURI: '/branches/create',
      DetailsURI: '/branches/branch',
      UpdateURI: '/branches/update',
      DeleteURI: '/branches/delete',
      CustomersURI: '/branches/customer',
      BranchStatusURI: '/branches/status',
      BranchLeadURI: '/branches/branchlead',
      ListFilterCustomersURI: '/branches/filtercustomers',
      ListFilterBranchURI: '/branches/filterbranches',
      ListFilterLeadURI: '/branches/filterbranchlead',
      GetScopeOfWorkURI: '/branches/getscopeofwork',
      ListFilterPartnerURI: '/branches/listpartners',
      ListFilterAgentsURI: '/branches/listpartners',
    },
    Profile: {
      DetailsURI: "/profiles/profile",
      UpdateURI: "/profiles/update",
      ImageURI: "/profiles/image",
      DeleteFilesURI: "/profiles/removeacceptedfiles",
    },
    Invoices: {
      ListURI: "/invoices",
      DownloadURI: "/invoices/downloadpdf",
      CustomersURI: '/branches/customer',
    },
    Analytics: {
      ListURI: "/analytics",
      ListCustomersURI: "/analytics/customers",
      ListBranchesURI: "/analytics/branches",
      ListQTOURI: "/analytics/qto",
      ListFTEURI: "/analytics/fte"
    },
    Dashboard: {
      ListDashboardURL: "/dashboard",
      ListCustomersURI: "/dashboard/customer",
      ListBranchesURI: "/dashboard/branches",
      ListChartDetailsURI: "/dashboard/chartdetails",
      CountDetailsURI: "/dashboard/countdetails",
      NpsChartURI: "/dashboard/npschart",
      InvoiceURI: "/dashboard/invoice",
      ListTopCustomerURI: "/dashboard/topcustomers",
      ListTopBillingURI: "/dashboard/topbillings",
      ListLatestReviewURI: "/dashboard/latestreviews",
      InvoiceSummaryURI: "/dashboard/invoiceSummary",
      StorageChartURI: "/dashboard/storage",
      ProgressChartURI: "dashboard/projectStatus",
      ProjectCountURI: "/dashboard/completedprojects",
    },
    Customers: {
      ListURI: "/customers",
      CreateURI: "/customers/create",
      DetailsURI: "/customers/customer",
      UpdateURI: "/customers/update",
      DeleteURI: "/customers/delete",
      CustometStatusURI: "/customers/status",
      ServicesURI: "/customers/services",
      PartnersURI: "/customers/partners",
      AgentsURI: "/customers/agentservicedetail",
      PartnerSubscriptionsURI: "/customers/partnerservicedetail",
      AgentSubscriptionsURI: "/customers/agentservicedetail",
      AddonsURI: "/customers/addons",
      UploadFileURI: "/customers/attachment",
      CreateFileDataURI: "/customers/createAttachment",
      RemoveFileDataURI: "/customers/removeAttachment",
      ExtendTrialURI: "/customers/extendTrial",
      ListPartners: "/customers/listpartners",
      ListSalesPerson: "/customers/salespersons",
      GetOtherServiceList: "customers/getotherserviceslist",
    },
    Projects: {
      CreateURI: "/projects/create",
      DetailsURI: "/projects/project",
      DetailedURI: "/projects/details",
      ConversationsURI: "/projects/comments",
      ConversationURI: "/projects/comment",
      DeleteConversationURI: "/projects/removecomment",
      EditConversationURI: "/projects/updatecomment",
      ActivitiesURI: "/projects/activities",
      UpdateURI: "/projects/update",
      DeleteURI: "/projects/delete",
      ExportPDFURI: "/projects/exportpdf",
      ExportProjectPDFURI: "/projects/exportpdf",
      ExportCSVURI: "/projects/exportcsv",
      ListUsersURI: "/projects/userlist",
      ListBranchesURI: "/projects/customerbranches",
      ListServicesURI: "/projects/services",
      ListAddedServicesURI: "/projects/addons",
      UploadFileURI: "/projects/attachment",
      ProjectAcceptURI: "/projects/accept",
      SendErrorRequestURI: "/projects/errorrequests",
      GlobalSearchURI: "/projects/search",
      CheckStatusURI: "projects/checkstatus",
      ProjectReopenURI: "/projects/reopenrequest",
      DeleteAttachment: "/projects/removeattachment",
      ListFilterBranchesURI: "/projects/filterbranches",
      listFilterCustomersURI: "/projects/customers",
      listFilterPartnersURI: "/projects/partners",
      CreateAdendumURI: "/projects/createaddendum",
      RemoveFileURI: "/projects/removefile",
      ProjectCancellationURI: "/projects/cancelproject",
      ProjectCancelRejectURI: "/projects/cancelrejectkey",
      DownloadURI: "/projects/downloadzip",
      GetScopeOfWorkURI: "/projects/getscopeofwork",
      GetDemoProjectsURI: '/projects/democount',
      RfiProjectsURI: '/projects/rfireply',
      GetRfiCommentsURI: 'projects/getrficomments',
      FetchLeaveURI: '/projects/fetchleavedata',
      DuerequestdetailsURI: '/projects/duerequestdetails',
      ApproveduedateURI: '/projects/approveduedate',
      RejectduedateURI: '/projects/rejectduedate',
      EditAssignee: '/projects/changeAssignee',
      DownloadFileURI: '/projects/downloadfile',
      GetOtherServiceList: 'projects/getotherservicelist',
      GetScopebyServiceID: '/projects/getscopebyserviceid',
      OtherServiceRequestdetailsURI: '/projects/otherservicerequestdetails',
      ApprovehoursURI: '/projects/approveotherservicehours',
      RejectHoursURI: '/projects/rejectotherservicehours',
    },
    Reports: {
      ListURI: "/reports",
      ReportsURI: "/reports/projectReports",
      ExportProjectPDFURI: "/reports/exportProjectpdf",
      ExportCSVURI: "/reports/exportProjectcsv",
      AddedReportsURI: "/reports/addonProjectReports",
      ExportDelayedReportsURI: '/reports/exportdelayedProjectcsv',
      ExportAddedPDFURI: "/reports/exportAddonpdf",
      ExportAddedCSVURI: "/reports/exportAddoncsv",
      ListFilterPartnerURI: "/reports/filterpartners",
      listFilterCustomersURI: "/reports/filtercustomers",
      ListFilterBranchURI: "/reports/filterbranches",
      DelayedProjectReportsURI: "/reports/delayedcompletedReports",
      ServiceURI: "/reports/services",
      ErrorReportingURI: "/reports/geterrorreportbyPagination",
      DownloadErrorReportURI: "/reports/geterrorReportDownload",

    },
    Storages: {
      ListURI: "/storage",
      DetailsURI: "/storage/filedetails",
      DeleteURI: "/storage/removemultifile",
    },
    Calendar: {
      ListURI: "/calendar",
      MonthListURI: "/calendar/monthview",
      WeekListURI: "/calendar/weekview",
    },
    NPS: {
      ListURL: "/review",
      CreateURL: "/review/create",
      NotNowURL: "/review/notnow",
      ShowPopup: "/review/showratepop",
      ListFilterBranchesURI: "/review/filterbranches",
      ListFilterCustomersURI: "/review/filtercustomers",
      ListFilterUsersURI: "/review/filterusers",
    },
    Holidays: {
      ListURI: '/holidays',
      CreateURI: '/holidays/create',
      DetailsURI: '/holidays/holiday',
      UpdateURI: '/holidays/update',
      DeleteURI: '/holidays/delete',
      HolidayInformation: '/holidays/notification',
    },
  };
}