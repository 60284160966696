import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { GetScopebyServiceIDAsync, GetOtherServiceListAsync, DownloadFileURIAsync, EditAssigneeURIAsync, ApproveDueDateURIAsync, RejectDueDateURIAsync, getDueRequestAsync, FetchLeaveAsync, UploadProjectFileAsync, projectReopenAsync, getAgentListAsync, GetScopeOfWorkAsync, checkStatusAsync, createProjectAsync, createProjectConversationAsync, deleteProjectAsync, deleteProjectCommentAsync, editProjectCommentAsync, exportCSVProjectsListAsync, exportPDFProjectsListAsync, exportProjectPDFAsync, getProjectActivitiesAsync, getProjectAddedServicesAsync, getProjectConversationsAsync, getProjectDetailedViewAsync, getProjectDetailsAsync, getProjectServicesAsync, getProjectUsersAsync, getProjectsAsync, updateProjectAsync, ProjectAcceptFileAsync, SendErrorRequestFileAsync, globaleSearchAsync, deleteProjectAttachmentAsync, getProjectCustomerBranchesAsync, listFilterBranchesAsync, listFilterCustomersAsync, listFilterPartnersAsync, createProjectAdendumAsync, removeFileAsync, projectCancellationAsync, projectCancelRejectAsync, downloadFileAsync, GetDemoProjectsCountAsync, RfiProjectsAsync, GetRfiCommentsAsync, getOtherServiceRequestAsync, ApproveHoursURIAsync, RejectHoursURIAsync } from "../api/projects";

const ProjectsContext = createContext();
export const ProjectsProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getProjects = async (data) => await getProjectsAsync(data, dispatch);
  const createProject = async (data) => await createProjectAsync(data, dispatch);
  const getProjectDetails = async (data) => await getProjectDetailsAsync(data, dispatch);
  const getProjectConversations = async (data) => await getProjectConversationsAsync(data, dispatch);
  const editProjectComment = async (data) => await editProjectCommentAsync(data, dispatch);
  const deleteProjectComment = async (data) => await deleteProjectCommentAsync(data, dispatch);
  const createProjectConversation = async (data) => await createProjectConversationAsync(data, dispatch);
  const getProjectActivities = async (data) => await getProjectActivitiesAsync(data, dispatch);
  const updateProject = async (data) => await updateProjectAsync(data, dispatch);
  const deleteProject = async (data) => await deleteProjectAsync(data, dispatch);
  const getProjectDetailedView = async (data) => await getProjectDetailedViewAsync(data, dispatch);
  const exportPDFProjectsList = async (data) => await exportPDFProjectsListAsync(data, dispatch);
  const exportCSVProjectsList = async (data) => await exportCSVProjectsListAsync(data, dispatch);
  const getProjectUsers = async (data) => await getProjectUsersAsync(data, dispatch);
  const getProjectCustomerBranches = async (data) => await getProjectCustomerBranchesAsync(data, dispatch);
  const getProjectServices = async (data) => await getProjectServicesAsync(data, dispatch);
  const getProjectAddedServices = async (data) => await getProjectAddedServicesAsync(data, dispatch);
  const UploadProjectFile = async (data) => await UploadProjectFileAsync(data, dispatch);
  const deleteProjectAttachment = async (data) => await deleteProjectAttachmentAsync(data, dispatch);
  const exportProjectPDF = async (data) => await exportProjectPDFAsync(data, dispatch);
  const projectAccept = async (data) => await ProjectAcceptFileAsync(data, dispatch);
  const sendErrorRequest = async (data) => await SendErrorRequestFileAsync(data, dispatch);
  const globaleSearch = async (data) => await globaleSearchAsync(data, dispatch);
  const checkStatus = async (data) => await checkStatusAsync(data, dispatch);
  const projectReopen = async (data) => await projectReopenAsync(data, dispatch);
  const listFilterBranches = async (data) => await listFilterBranchesAsync(data, dispatch);
  const listFilterCustomers = async (data) => await listFilterCustomersAsync(data, dispatch);
  const listFilterPartners = async (data) => await listFilterPartnersAsync(data, dispatch);
  const createProjectAdendum = async (data) => await createProjectAdendumAsync(data, dispatch);
  const removefile = async (data) => await removeFileAsync(data, dispatch);
  const projectCancellation = async (data) => projectCancellationAsync(data, dispatch);
  const projectCancelReject = async (data) => projectCancelRejectAsync(data, dispatch);
  const downloadFile = async (data) => downloadFileAsync(data, dispatch);
  const BranchGetScopeOfWork = async (data) => GetScopeOfWorkAsync(data, dispatch);
  const GetDemoProjectsCount = async (data) => GetDemoProjectsCountAsync(data, dispatch);
  const getAgentList = async (data) => getAgentListAsync(data, dispatch);
  const rfiProjects = async (data) => RfiProjectsAsync(data, dispatch);
  const GetRfiComments = async (data) => GetRfiCommentsAsync(data, dispatch);
  const fetchLeave = async (data) => FetchLeaveAsync(data, dispatch);
  const getDueRequest = async (data) => getDueRequestAsync(data, dispatch);
  const ApproveDueDate = async (data) => ApproveDueDateURIAsync(data, dispatch);
  const RejectDueDate = async (data) => RejectDueDateURIAsync(data, dispatch);
  const editAssignee = async (data) => EditAssigneeURIAsync(data, dispatch);
  const downloadFileLink = async (data) => DownloadFileURIAsync(data, dispatch);
  const getOtherServiceList = async (data) => GetOtherServiceListAsync(data, dispatch);
  const getScopebyServiceID = async (data) => GetScopebyServiceIDAsync(data, dispatch);
  const getOtherServiceRequest = async (data) => getOtherServiceRequestAsync(data, dispatch);
  const ApproveHours = async (data) => ApproveHoursURIAsync(data, dispatch);
  const RejectHours = async (data) => RejectHoursURIAsync(data, dispatch);

  const values = {
    getScopebyServiceID,
    getOtherServiceList,
    downloadFileLink,
    editAssignee,
    ApproveDueDate,
    RejectDueDate,
    getDueRequest,
    BranchGetScopeOfWork,
    getProjects,
    fetchLeave,
    getAgentList,
    createProject,
    getProjectDetails,
    updateProject,
    deleteProject,
    getProjectDetailedView,
    exportPDFProjectsList,
    exportCSVProjectsList,
    getProjectUsers,
    getProjectServices,
    getProjectAddedServices,
    UploadProjectFile,
    getProjectConversations,
    createProjectConversation,
    getProjectActivities,
    editProjectComment,
    deleteProjectComment,
    exportProjectPDF,
    projectAccept,
    sendErrorRequest,
    globaleSearch,
    checkStatus,
    projectReopen,
    deleteProjectAttachment,
    getProjectCustomerBranches,
    listFilterBranches,
    listFilterCustomers,
    listFilterPartners,
    createProjectAdendum,
    removefile,
    projectCancellation,
    projectCancelReject,
    downloadFile,
    GetDemoProjectsCount,
    rfiProjects,
    GetRfiComments,
    getOtherServiceRequest,
    ApproveHours,
    RejectHours
  };
  return (
    <ProjectsContext.Provider value={values}>
      {children}
    </ProjectsContext.Provider>
  );
};
export default ProjectsContext;